import httpClient from '../httpClient/httpClient';

const KeyTexService = {
  getKeyChain() {
    return httpClient.get('api/keytex/get-key-chain');
  },
  decrypt(encryptedText, keyChainIndex, keyInput, encoding, ivAttachment) {
    return httpClient.post('api/keytex/decrypt', {
      encryptedText,
      keyChainIndex,
      keyInput,
      encoding,
      ivAttachment,
    });
  },
  encrypt(plainText, keyChainIndex, keyInput, encoding, ivAttachment) {
    return httpClient.post('api/keytex/encrypt', {
      plainText,
      keyChainIndex,
      keyInput,
      encoding,
      ivAttachment,
    });
  },
  generateKey(keySize) {
    return httpClient.get('api/keytex/generate-key', {
      params: {
        keySize,
      },
    });
  },
};

export default KeyTexService;
