<template>
  <v-container v-if="hasPermission" fluid>
    <mex-sperm-spinner v-if="false" :spinnerText="getDashboardDataLoadingSpinnerText" />
    <template v-else>
      <mex-heading content="keyTEX" />
      <v-row justify="center">
        <v-col cols="8">
          <mex-card cardClass="foreground" icon="mdi-key-chain-variant" title="keyTEX">
            <v-row class="ma-1">
              <v-col cols="11">
                <v-row justify="center">
                  <v-col align-self="center">
                    <v-text-field
                      dense
                      hide-details
                      label="ALGORITM - CIPHERMODE"
                      outlined
                      readonly
                      value="AES-256-CBC"
                    />
                  </v-col>
                  <v-col align-self="center">
                    <v-radio-group v-model="ivAttachment" row>
                      <template v-slot:label>
                        <div>IV ATTACHMENT:</div>
                      </template>
                      <v-radio value="start">
                        <template v-slot:label>
                          <div>start</div>
                        </template>
                      </v-radio>
                      <v-radio value="end">
                        <template v-slot:label>
                          <div>end</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col align-self="center">
                    <v-radio-group v-model="encoding" row>
                      <template v-slot:label>
                        <div>ENCODING:</div>
                      </template>
                      <v-radio value="hex">
                        <template v-slot:label>
                          <div>hex</div>
                        </template>
                      </v-radio>
                      <v-radio value="base64">
                        <template v-slot:label>
                          <div>base64</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-tabs
                  v-model="currentTab"
                  background-color="transparent"
                  color="primaryAccent"
                  grow
                >
                  <v-tab>
                    Encrypt
                  </v-tab>
                  <v-tab>
                    Decrypt
                  </v-tab>
                  <v-tab :disabled="!hasAllPermission">
                    Generate Key
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="currentTab" style="background-color: transparent">
                  <v-tab-item>
                    <!-- ------------------------------------  ENCRYPT ------------------------------------  -->
                    <v-card color="foreground">
                      <v-row class="ma-1" justify="end">
                        <v-col cols="11">
                          <v-text-field
                            v-model="plainText"
                            dense
                            hide-details
                            label="Plain Text"
                            outlined
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ma-1" justify="end">
                        <v-col cols="1">
                          <mex-btn
                            :icon="keyInputActive ? 'mdi-key-chain' : 'mdi-key'"
                            iconOnly
                            @click="keyChainSwitch"
                          />
                        </v-col>
                        <v-col v-if="keyInputActive" cols="11">
                          <v-text-field
                            v-model="keyInput"
                            dense
                            hide-details
                            label="Key Input"
                            outlined
                          />
                        </v-col>
                        <v-col v-else cols="11">
                          <v-select
                            v-model="keyChainIndex"
                            :items="keyChainItems"
                            dense
                            hide-details
                            label="Key Chain"
                            outlined
                          />
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="auto">
                          <mex-btn content="Encrypt" @click="encrypt" />
                        </v-col>
                        <v-col cols="auto">
                          <mex-btn content="Switch" @click="switchToDecrypt" />
                        </v-col>
                        <v-col cols="auto">
                          <mex-btn content="Clear" @click="clear" />
                        </v-col>
                      </v-row>
                      <v-row class="ma-1" justify="end">
                        <v-col cols="11">
                          <v-text-field
                            v-model="encryptedText"
                            dense
                            hide-details
                            label="Encrypted Text"
                            outlined
                          />
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="11">
                          <v-textarea
                            outlined
                            :value="keytexResponse"
                            :background-color="keytexResponseColor"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <!-- ------------------------------------  DECRYPT ------------------------------------  -->
                    <v-row class="ma-1" justify="end">
                      <v-col cols="11">
                        <v-text-field
                          v-model="encryptedText"
                          dense
                          hide-details
                          label="Encrypted Text"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row class="ma-1" justify="end">
                      <v-col cols="1">
                        <mex-btn
                          :icon="keyInputActive ? 'mdi-key-chain' : 'mdi-key'"
                          iconOnly
                          @click="keyChainSwitch"
                        />
                      </v-col>
                      <v-col v-if="keyInputActive" cols="11">
                        <v-text-field
                          v-model="keyInput"
                          dense
                          hide-details
                          label="Key Input"
                          outlined
                        />
                      </v-col>
                      <v-col v-else cols="11">
                        <v-select
                          v-model="keyChainIndex"
                          :items="keyChainItems"
                          dense
                          hide-details
                          label="Key Chain"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="auto">
                        <mex-btn content="Decrypt" @click="decrypt" />
                      </v-col>
                      <v-col cols="auto">
                        <mex-btn content="Switch" @click="switchToEncrypt" />
                      </v-col>
                      <v-col cols="auto">
                        <mex-btn content="Clear" @click="clear" />
                      </v-col>
                    </v-row>
                    <v-row class="ma-1" justify="end">
                      <v-col cols="11">
                        <v-text-field
                          v-model="plainText"
                          dense
                          hide-details
                          label="Plain Text"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="11">
                        <v-textarea
                          outlined
                          :value="keytexResponse"
                          :background-color="keytexResponseColor"
                        />
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <!-- ------------------------------------  KEY GEN ------------------------------------  -->
                    <v-row class="ma-1" justify="center">
                      <v-col cols="auto" align-self="center">
                        <v-text-field
                          v-model="keySize"
                          dense
                          hide-details
                          label="Key size"
                          type="number"
                          min="0"
                          max="256"
                          outlined
                        />
                      </v-col>
                      <v-col cols="3" align-self="center">
                        <mex-p content="Bytes" class="mb-0" />
                      </v-col>
                      <v-col cols="auto">
                        <mex-btn content="generate key" @click="generateKey" />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="generatedKey"
                          dense
                          hide-details
                          type="text"
                          outlined
                        />
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </mex-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import KeyTexService from "../services/keyTEX.service";
import requiredPermissions from "../requiredPermissions";

export default {
  name: "keyTEX",
  data() {
    return {
      // Visualization
      keytexDataLoading: true,
      keytexDataLoadingText: "keyTEX data loading",
      // config
      ivAttachment: "start",
      encoding: "hex",
      // Tabs
      currentTab: 0,
      // keyChain
      keyInputActive: false,
      keyChainItems: [],
      // encryption / decryption
      encryptedText: "",
      plainText: "",
      keyInput: "",
      keyChainIndex: 1,
      keytexResponse: "",
      keytexResponseColor: "transparent",
      // key generating
      keySize: 32,
      generatedKey: "",
      hasPermission: false,
      hasAllPermission: false,
    };
  },
  created() {
    if (this.isLoggedIn) {
      this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.keyTEXAny, this.$store)
        .then((hasPermission) => {
          if (hasPermission) {
            this.hasPermission = true;
            this.getKeyChain();
            this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.keyTEXAll, this.$store)
              .then((hasAllPermission) => {
                this.hasAllPermission = hasAllPermission;
              })
              .catch(() => {
                this.$router.push({ name: "NotFound" });
              })
          } else {
            this.$router.push({ name: "NotFound" });
          }
        })
        .catch(() => {
          this.$router.push({ name: "NotFound" });
        })
    }
  },
  computed: {
    ...mapGetters("sysAuth", ["isLoggedIn", "getUser", "getUserPermissions"])
  },
  methods: {
    getKeyChain() {
      KeyTexService.getKeyChain().then(repsonse => {
        repsonse.data.forEach(key => {
          this.keyChainItems.push({
            text: key.name,
            value: key.keyTEXID
          });
        });
      });
    },
    decrypt() {
      this.plainText = "";
      KeyTexService.decrypt(
        this.encryptedText,
        this.keyChainIndex,
        this.keyInput,
        this.encoding,
        this.ivAttachment
      )
        .then(response => {
          this.plainText = response.data;
          this.keytexResponse = "Successfully decrypted";
          this.keytexResponseColor = "green";
        })
        .catch(err => {
          this.keytexResponse = err.response.data;
          this.keytexResponseColor = "red";
        });
    },
    encrypt() {
      this.encryptedText = "";
      KeyTexService.encrypt(
        this.plainText,
        this.keyChainIndex,
        this.keyInput,
        this.encoding,
        this.ivAttachment
      )
        .then(response => {
          this.encryptedText = response.data;
          this.keytexResponse = "Successfully encrypted";
          this.keytexResponseColor = "green";
        })
        .catch(err => {
          this.keytexResponse = err.response.data;
          this.keytexResponseColor = "red";
        });
    },
    generateKey() {
      this.generatedKey = "";
      KeyTexService.generateKey(this.keySize)
        .then(response => {
          this.generatedKey = response.data;
        })
        .catch(err => {
          this.$toast.error(err.response.data);
        });
    },
    switchToDecrypt() {
      this.plainText = "";
      this.currentTab = 1;
    },
    switchToEncrypt() {
      this.encryptedText = "";
      this.currentTab = 0;
    },
    clear() {
      this.encryptedText = "";
      this.plainText = "";
      this.keyInput = "";
      this.keyChainIndex = 0;
      this.keytexResponse = "";
      this.keytexResponseColor = "transparent";
    },
    keyChainSwitch() {
      if (this.keyInputActive) {
        this.keyInputActive = false;
        this.keyInput = "";
        this.keyChainIndex = 1;
      } else {
        this.keyInputActive = true;
        this.keyChainIndex = -1;
      }
    }
  }
};
</script>
